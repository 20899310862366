import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { head } from 'lodash';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';

import AcdCycleIcon from '../../../assets/icons/acd-cycle.svg?react';
import CustomerIcon from '../../../assets/icons/customer.svg?react';
import RocIcon from '../../../assets/icons/roc.svg?react';
import { QUERY_GET_USER_AUTH_INFO } from '../../../services/queries';
import { MainNavMenuButton, MainNavMenuButtonProps } from '../../5-elements';

export const MainNavMenu = (): JSX.Element => {
  const { t } = useTranslation();
  const { user } = useAuth0();
  const {
    data: dataUser,
    loading: loadingUser,
    error: errorUser
  } = useQuery(QUERY_GET_USER_AUTH_INFO, {
    variables: { userAuthId: user?.sub || '' },
    skip: !user?.sub
  });
  if (errorUser || !dataUser || loadingUser) {
    return <></>;
  }
  const showCustomers = head(dataUser.users)!.isSuperUser || !head(dataUser.users)!.company.customer;

  const key = showCustomers ? 'mainNavMenu.customers' : 'mainNavMenu.home';

  const mainNavMenuConfig: MainNavMenuButtonProps[] = [
    { label: t(key), icon: <CustomerIcon />, to: '/portal/customers' },
    { label: t('mainNavMenu.devices'), icon: <RocIcon />, to: '/portal/devices' },
    { label: t('mainNavMenu.operations'), icon: <AcdCycleIcon />, to: '/portal/operations' }
  ];

  return (
    <nav className="main-nav-menu" data-testid="portal-main-nav-menu">
      {mainNavMenuConfig.map((menuItem) => (
        <MainNavMenuButton key={menuItem.label} {...menuItem} />
      ))}
    </nav>
  );
};
