import classNames from 'classnames';

import AnnotatedIcon from '../../../../assets/icons/annotated.svg?react';
import ChangeIcon from '../../../../assets/icons/changed.svg?react';
import { OperationAnnotationStatusProps } from '../../../../types';

export const OperationAnnotationIcon = ({
  deviceResultId,
  deviceAnnotatedResultId
}: OperationAnnotationStatusProps): JSX.Element => {
  const baseClassName = 'operation-annotation-icon';

  if (deviceResultId && deviceAnnotatedResultId) {
    if (deviceAnnotatedResultId === deviceResultId) {
      return (
        <AnnotatedIcon
          className={classNames(baseClassName, `${baseClassName}--confirmed`)}
          data-testid="operation-annotation-icon-confirmed"
        />
      );
    }

    return (
      <ChangeIcon
        className={classNames(baseClassName, `${baseClassName}--overridden`)}
        data-testid="operation-annotation-icon-overridden"
      />
    );
  }

  return <AnnotatedIcon className={baseClassName} data-testid="operation-annotation-icon-to-be-checked" />;
};
