import { OperationAnnotationIcon, OperationResultChip, OperationResultChipProps } from '../..';
import { OperationAnnotationStatusProps } from '../../../../types';

export interface DataGridOperationResultCellProps extends OperationAnnotationStatusProps, OperationResultChipProps {}

export const DataGridOperationResultCell = ({
  deviceAnnotatedResultId,
  deviceResultId,
  title,
  label,
  hasEndedAt,
  success
}: DataGridOperationResultCellProps): JSX.Element => {
  return (
    <div className="data-grid-operation-result-cell" data-testid="data-grid-operation-result-cell">
      <div className="data-grid-operation-result-cell__operation-annotation-icon-container">
        <OperationAnnotationIcon deviceResultId={deviceResultId} deviceAnnotatedResultId={deviceAnnotatedResultId} />
      </div>
      <OperationResultChip title={title} label={label} hasEndedAt={hasEndedAt} success={success} />
    </div>
  );
};
