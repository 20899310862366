import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  defaultDataIdFromObject
} from '@apollo/client';
import { setContext } from '@apollo/link-context';
import { OAuthError, useAuth0 } from '@auth0/auth0-react';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { t } from 'i18next';
import { includes } from 'lodash';
import { JSX, PropsWithChildren, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { graphqlApiConfig } from '../../configs';
import { getRedirectFullPath } from '../../utilities';
import { ErrorPage } from '../1-pages';
import { PageWrapper } from '../2-templates';

type ApolloClientProviderProps = PropsWithChildren;

export const ApolloClientProvider = ({ children }: ApolloClientProviderProps): JSX.Element => {
  const { getAccessTokenSilently } = useAuth0();
  const timeoutLink = new ApolloLinkTimeout(graphqlApiConfig.defaultTimeout);
  const httpLink = new HttpLink({
    uri: `${graphqlApiConfig.host}${graphqlApiConfig.graphqlEndpoint}`
  });
  const timeoutHttpLink = timeoutLink.concat(httpLink);
  const routerLocation = useLocation();
  const fullPath = getRedirectFullPath(routerLocation);

  const authLink = setContext(async (_, { headers, ...rest }) => {
    let token;
    try {
      token = await getAccessTokenSilently();
    } catch (error: unknown) {
      const typedError = error as OAuthError;
      return (
        <PageWrapper loginRequired={false} loginRedirectUri={fullPath} hasNavigation={false}>
          <ErrorPage
            title={t('errorPage.errorOccurred')}
            titleEmphasized={t('errorPage.error')}
            message={typedError.message}
          />
        </PageWrapper>
      );
    }

    if (!token) return { headers, ...rest };

    return {
      ...rest,
      headers: {
        ...headers,
        authorization: `Bearer ${token}`
      }
    };
  });

  const apolloClient = useRef<ApolloClient<NormalizedCacheObject>>();

  if (!apolloClient.current) {
    // Apollo issue: Typescript thinks the type ApolloLink from '@apollo/client'
    // is different from the type ApolloLink from '@apollo/link-context'. Therefore we need ts-ignore.
    apolloClient.current = new ApolloClient({
      // @ts-expect-error The expected type comes from property 'link' which is declared here on type 'ApolloClientOptions<NormalizedCacheObject>'
      link: authLink.concat(timeoutHttpLink),
      cache: new InMemoryCache({
        dataIdFromObject(responseObject) {
          if (
            responseObject.__typename === 'DeviceOperationImageUrl' &&
            includes(responseObject.url as string, 'thumb')
          ) {
            return `DeviceOperationImageUrl:${responseObject.id}-thumb`;
          }
          return defaultDataIdFromObject(responseObject);
        },
        typePolicies: {
          // The fields listed below do not use `id` as their primary key. We need to tell Apollo for the non-`id`
          // primary keys.
          Timezone: {
            keyFields: ['name']
          },
          TimezoneInfo: {
            keyFields: ['name']
          },
          Customer: {
            keyFields: ['companyId']
          },
          ServiceProvider: {
            keyFields: ['companyId']
          }
        }
      })
    });
  }

  return <ApolloProvider client={apolloClient.current}>{children}</ApolloProvider>;
};
