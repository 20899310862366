import { CustomerOrderBy, OrderBy } from '../../../../__generated__/graphql';
import { DEFAULT_GET_CUSTOMERS_SORT_BY } from '../../../../constants';

export enum CustomersOverviewSortOptions {
  NameAsc = 'name',
  NameDesc = '-name',
  DevicesCountAsc = 'devicesCount',
  DevicesCountDesc = '-devicesCount',
  SitesCountAsc = 'sitesCount',
  SitesCountDesc = '-sitesCount',
  ProgramsCountAsc = 'programsCount',
  ProgramsCountDesc = '-programsCount',
  operationalPerformanceAsc = 'operationalPerformance',
  operationalPerformanceDesc = '-operationalPerformance'
}

export const generateSortQuery = (sortOption?: CustomersOverviewSortOptions): CustomerOrderBy[] => {
  switch (sortOption) {
    case CustomersOverviewSortOptions.NameAsc:
      return DEFAULT_GET_CUSTOMERS_SORT_BY;
    case CustomersOverviewSortOptions.NameDesc:
      return [{ company: { name: OrderBy.Desc } }];
    case CustomersOverviewSortOptions.SitesCountAsc:
      return [{ sitesAggregate: { count: OrderBy.Asc } }, DEFAULT_GET_CUSTOMERS_SORT_BY[0]];
    case CustomersOverviewSortOptions.SitesCountDesc:
      return [{ sitesAggregate: { count: OrderBy.Desc } }, DEFAULT_GET_CUSTOMERS_SORT_BY[0]];
    case CustomersOverviewSortOptions.DevicesCountAsc:
      return [{ devicesAggregate: { count: OrderBy.Asc } }, DEFAULT_GET_CUSTOMERS_SORT_BY[0]];
    case CustomersOverviewSortOptions.DevicesCountDesc:
      return [{ devicesAggregate: { count: OrderBy.Desc } }, DEFAULT_GET_CUSTOMERS_SORT_BY[0]];
    case CustomersOverviewSortOptions.ProgramsCountAsc:
      return [{ programsAggregate: { count: OrderBy.Asc } }, DEFAULT_GET_CUSTOMERS_SORT_BY[0]];
    case CustomersOverviewSortOptions.ProgramsCountDesc:
      return [{ programsAggregate: { count: OrderBy.Desc } }, DEFAULT_GET_CUSTOMERS_SORT_BY[0]];
    case CustomersOverviewSortOptions.operationalPerformanceAsc:
      return [
        { customerCurrentPerformance: { performanceLastPeriodPct: OrderBy.AscNullsFirst } },
        DEFAULT_GET_CUSTOMERS_SORT_BY[0]
      ];
    case CustomersOverviewSortOptions.operationalPerformanceDesc:
      return [
        { customerCurrentPerformance: { performanceLastPeriodPct: OrderBy.DescNullsLast } },
        DEFAULT_GET_CUSTOMERS_SORT_BY[0]
      ];
    default:
      return DEFAULT_GET_CUSTOMERS_SORT_BY;
  }
};
