import { JSX } from 'react';

interface CountBlockProps {
  title: string;
  count: number;
}

export const CountBlock = ({ title, count }: CountBlockProps): JSX.Element => {
  return (
    <div className="count-block">
      <h4>{title}</h4>
      <p className="count-block__count">{count}</p>
    </div>
  );
};
