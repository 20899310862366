import { JSX } from 'react';
import { useTranslation } from 'react-i18next';

import { appConfig } from '../../../configs';
import { RSLinkTimezone } from '../rs-link-timezone';

export interface CustomerSiteProgramSubtitleProps {
  customerName: string; // A company (customer or service provide) `name` is required
  siteName: string; // For a device, a Site is required
  programNumber?: string; // A device may have a program number, but not required
  customerId: string;
}

export const CustomerSiteProgramSubtitle = ({
  customerName,
  siteName,
  programNumber,
  customerId
}: CustomerSiteProgramSubtitleProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="customer-site-program-subtitle" data-testid="customer-site-program-subtitle">
      <RSLinkTimezone
        className="customer-site-program-subtitle__customer-link"
        data-testid="customer-site-program-subtitle-customer-link"
        to={`${appConfig.basePath}/customers/${customerId}`}
      >
        {customerName}
      </RSLinkTimezone>
      <span className="customer-site-program-subtitle__site-program">
        {` ${t('divider')} ${siteName}`}
        {programNumber && ` ${t('divider')} ${programNumber}`}
      </span>
    </div>
  );
};
