import { faker } from '@faker-js/faker';

import { GetOperationsQuery } from '../../../__generated__/graphql';

// Generate 90 items for pagination test
export const fakerGeneratedOperations = (): GetOperationsQuery['deviceOperations'] => {
  const operations: GetOperationsQuery['deviceOperations'] = [];
  for (let i = 0; i < 90; i += 1) {
    const fakeCompanyId = faker.string.uuid();
    const fakeCompanyName = faker.company.name();
    const isSuccessful = faker.datatype.boolean({ probability: 0.5 });
    let fakeDeviceOperationResult;
    let fakeDeviceOperationFinalResult;

    const fakeSuccessOperationResultId = '48923bbd-59ad-4a93-9202-9c662edea7f2';
    const fakeFailedOperationResultId = 'b897efb6-458c-498c-991d-d2d8a6ea66f7';

    if (isSuccessful) {
      fakeDeviceOperationResult = { id: fakeSuccessOperationResultId };
      fakeDeviceOperationFinalResult = {
        id: fakeSuccessOperationResultId,
        isSuccessful: true,
        name: 'Success',
        code: 'SUCCESS'
      };
    } else {
      fakeDeviceOperationResult = { id: fakeFailedOperationResultId };
      fakeDeviceOperationFinalResult = {
        id: fakeFailedOperationResultId,
        isSuccessful: false,
        name: 'No socket detected',
        code: 'NO_SOCKET_DETECTED'
      };
    }

    operations.push({
      id: faker.string.uuid(),
      sequence: faker.number.int({ min: 1, max: 100 }),
      startAt: faker.date.between({ from: '2024-01-01T00:00:00.000Z', to: '2024-01-01T07:00:00.000Z' }).toISOString(),
      endAt: faker.date.between({ from: '2024-01-01T08:00:00.000Z', to: '2024-01-01T16:00:00.000Z' }).toISOString(),
      operationalLifeCycle: 'OPERATIONAL',
      success: isSuccessful,
      errorDetails: 'auto detected error',
      isAnnotated: false,
      deviceOperationResult: fakeDeviceOperationResult,
      deviceOperationAnnotatedResult: null,
      deviceOperationFinalResult: fakeDeviceOperationFinalResult,
      device: {
        id: faker.string.uuid(),
        serialNumber: faker.string.alpha(10),
        site: {
          id: faker.string.uuid(),
          name: faker.company.name(),
          customer: {
            companyId: fakeCompanyId,
            company: {
              id: fakeCompanyId,
              name: fakeCompanyName
            }
          }
        },
        program: {
          id: faker.string.uuid(),
          name: faker.string.numeric(9)
        }
      }
    });
  }
  return operations;
};

export const fakerGeneratedOperationsResponse: GetOperationsQuery = {
  deviceOperationsAggregate: { aggregate: { count: 90 } },
  deviceOperations: fakerGeneratedOperations()
};

export const getOperationsResponse: GetOperationsQuery = {
  deviceOperationsAggregate: { aggregate: { count: 3 } },
  deviceOperations: [
    {
      // Annotated: confirmed
      id: '2f46e9d0-914f-4719-998d-4a944288a0e4',
      sequence: 86,
      startAt: '2024-06-03T18:46:59.467+00:00',
      endAt: '2024-06-03T18:53:43.467+00:00',
      operationalLifeCycle: 'OPERATIONAL',
      success: true,
      errorDetails: 'auto detected error',
      isAnnotated: false,
      deviceOperationResult: { id: '48923bbd-59ad-4a93-9202-9c662edea7f2' },
      deviceOperationAnnotatedResult: { id: '48923bbd-59ad-4a93-9202-9c662edea7f2' },
      deviceOperationFinalResult: {
        id: '48923bbd-59ad-4a93-9202-9c662edea7f2',
        isSuccessful: true,
        name: 'Success',
        code: 'SUCCESS'
      },
      device: {
        id: 'f7382460-ad94-45f6-bf82-61acb6964737',
        serialNumber: 'ROC0000501',
        site: {
          id: '66ba6fd5-a7d7-4849-94db-0379c7f0490a',
          name: 'Good Ev Site1',
          customer: {
            companyId: '7b946783-9deb-46eb-8ef5-d95ace8fa587',
            company: {
              id: '7b946783-9deb-46eb-8ef5-d95ace8fa587',
              name: 'Good EV Comp'
            }
          }
        },
        program: {
          id: '805f793c-89bd-46e9-9431-9090b232a861',
          name: '202315974 - Good EV Comp'
        }
      }
    },
    {
      // In progress
      id: 'be4714d5-6139-4cfe-b91a-60b8c2b91537',
      sequence: 41,
      startAt: '2024-06-03T15:44:22.124+00:00',
      endAt: null,
      operationalLifeCycle: 'OPERATIONAL',
      success: null,
      errorDetails: 'auto detected error',
      isAnnotated: false,
      deviceOperationResult: null,
      deviceOperationAnnotatedResult: null,
      deviceOperationFinalResult: null,
      device: {
        id: 'd08baf5a-697e-470b-8dc6-e5e749a0abf3',
        serialNumber: 'ROC00000401',
        site: {
          id: '5e3cc008-3473-49c9-a442-93a4819093d0',
          name: 'Rocsys Factory',
          customer: {
            companyId: '5e170425-f850-4536-a698-56db0918ab2f',
            company: {
              id: '5e170425-f850-4536-a698-56db0918ab2f',
              name: 'Rocsys Production Factory'
            }
          }
        },
        program: {
          id: 'f9473faa-83dc-4453-abe4-33b19321f5bd',
          name: '202385528 - Rocsys Production Factory'
        }
      }
    },
    {
      // Not annotated
      id: 'b2357c12-4e24-46b7-a910-6e2151fed725',
      sequence: 85,
      startAt: '2024-06-03T13:22:08.078+00:00',
      endAt: '2024-06-03T13:29:20.078+00:00',
      operationalLifeCycle: 'OPERATIONAL',
      success: false,
      errorDetails: 'auto detected error',
      isAnnotated: false,
      deviceOperationResult: { id: 'b897efb6-458c-498c-991d-d2d8a6ea66f7' },
      deviceOperationAnnotatedResult: null,
      deviceOperationFinalResult: {
        id: 'b897efb6-458c-498c-991d-d2d8a6ea66f7',
        isSuccessful: false,
        name: 'No socket detected',
        code: 'NO_SOCKET_DETECTED'
      },
      device: {
        id: 'f7382460-ad94-45f6-bf82-61acb6964737',
        serialNumber: 'ROC0000501',
        site: {
          id: '66ba6fd5-a7d7-4849-94db-0379c7f0490a',
          name: 'Good Ev Site1',
          customer: {
            companyId: '5e170425-f850-4536-a698-56db0918ab2f',
            company: {
              id: '5e170425-f850-4536-a698-56db0918ab2f',
              name: 'Rocsys Production Factory'
            }
          }
        },
        program: null
      }
    }
  ]
};
