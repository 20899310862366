import i18n from '../../i18n';
import { MetricChangeData, MetricData } from '../../types';

// See this for the business requirements:
// https://rocsys.atlassian.net/wiki/spaces/CLOUD/pages/627114007/Rocsys+Portal+Operational+Performance+Details+-+data+handling+proposal

/**
 * Generate the display value (number) for metric.
 *
 * @param {MetricData['value']} value The actual metric value.
 * @param {MetricData['decimalPlaces']} [decimalPlaces=1] The decimal places to display.
 * @returns {string} The display value.
 */
export const getMetricValue = (value: MetricData['value'], decimalPlaces: MetricData['decimalPlaces'] = 1): string => {
  if (value === 0) {
    // We want to show 0 instead of 0.0
    return value.toFixed(0);
  }
  if (value === null || value === undefined) {
    return i18n.t('noData');
  }

  return value.toFixed(decimalPlaces);
};

/**
 * Generate the display value (number) for metric change.
 *
 * @param {MetricChangeData['value']} value The actual metric change value.
 * @param {MetricChangeData['decimalPlaces']} [decimalPlaces=1] The decimal places to display.
 * @returns {string} The display value.
 */
export const getMetricChangeValue = (
  value: MetricChangeData['value'],
  decimalPlaces: MetricChangeData['decimalPlaces'] = 1
): string => {
  if (value === null || value === undefined || value === Infinity) {
    return ''; // empty string
  }

  if (value === 0) {
    // We want to show 0 instead of 0.0
    return value.toFixed(0);
  }

  return Math.abs(value).toFixed(decimalPlaces);
};
